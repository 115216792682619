<template>
  <div class="layout">
    <div class="title">
      <div class="title-left">
        <a-typography-title :level="3">{{$t('history-page.history')}}</a-typography-title>

        <div class="title-filter">
          <a-select placeholder="12 months" style="width: 174px" value="1">
            <a-select-option value="1">12 months</a-select-option>
          </a-select>
        </div>
      </div>

      <!-- <div class="title-right">
        <a href="" class="export-report">Export report</a>
      </div> -->
    </div>

    <div class="table" v-if="!isLoading">
      <a-table
        class="table-history"
        :class="{ full: selectedVoyage.id === null }"
        :columns="columns"
        :data-source="dataSource.list"
        :row-class-name="
          (record) =>
            record.voyage_id == selectedVoyage.id ? 'table-row-selected' : null
        "
        :scroll="{ x: '1400px', y: 'calc(100vh - 244px)' }"
        :customRow="customRow"
        :pagination="false"
        bordered
      >
        <template #headerCell="{ column }">
          <template v-if="column.key === 'vessel'">
            <span class="table-head">{{ column.title }}</span>
          </template>

          <template v-if="column.key === 'voyage'">
            <span class="table-head">{{ column.title }}</span>
          </template>

          <template v-if="column.key === 'departure'">
            <span class="table-head">{{ column.title }}</span>
          </template>

          <template v-if="column.key === 'arrival'">
            <span class="table-head">{{ column.title }}</span>
          </template>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'vessel_name'">
            {{ record.vessel_name }}
          </template>

          <template v-if="column.dataIndex === 'vessel_type'">
            <a-typography-text type="secondary">
              {{ record.vessel_type }}
            </a-typography-text>
          </template>

          <template v-if="column.key === 'voyage_id'">
            <a-typography-text>{{ record.voyage_id }}</a-typography-text>
          </template>

          <template v-if="column.key === 'voyage_type'">
            <a-typography-text>{{ record.voyage_type }}</a-typography-text>
          </template>

          <template v-if="column.key === 'departure_port_name'">
            <a-typography-text>
              {{ record.departure_port_name }}
              <span style="color: #9fa2ae">
                {{ record.departure_port_locode }}
              </span>
            </a-typography-text>
          </template>

          <template v-if="column.key === 'departure_datetime'">
            <a-typography-text>
              {{ date.formatUTC(record.departure_datetime) }}
            </a-typography-text>
          </template>

          <template v-if="column.key === 'arrival_port_name'">
            <a-typography-text>
              {{ record.arrival_port_name }}
              <span style="color: #9fa2ae">
                {{ record.arrival_port_locode }}
              </span>
            </a-typography-text>
          </template>

          <template v-if="column.key === 'arrival_datetime'">
            <a-typography-text>
              {{ date.formatUTC(record.arrival_datetime) }}
            </a-typography-text>
          </template>
        </template>
      </a-table>

      <HistoryDetails
        v-if="selectedVoyage.id"
        :loading="isLoadingVoyage"
        :voyage="voyageDetails"
      />
    </div>
    <a-skeleton active class="loading" v-else />
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  onMounted,
  watchEffect,
  reactive,
  ref,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import { useLoading } from "@/composables/useLoading";
import useVoyages from "@/composables/useVoyages";
import { HistoryDetails } from "./components";

import { icons } from "@/assets";
import { date } from "@/helpers";

export default defineComponent({
  name: "History",

  setup() {
    const route = useRoute();
    const router = useRouter();

    const { t } = useI18n();

    const id = route.params.id || null;
    const dataSource = reactive({ list: [] });
    const loading = useLoading();
    const loadingVoyage = useLoading();
    const voyages = useVoyages();
    const selectedVoyage = reactive({ id: id });

    const list = computed(() => voyages.voyages());
    const voyageDetails = computed(() => voyages.voyageDetails());

    onMounted(() => {
      loading.start();

      voyages.fetchVoyages().then(() => {
        loading.finish();
      });
    });

    watchEffect(() => {
      selectedVoyage.id = route.params.id || null;

      if (!dataSource.list.length) {
        dataSource.list = list;
      }

      if (selectedVoyage.id) {
        loadingVoyage.start();

        voyages.fetchVoyageDetails(selectedVoyage.id).then(() => {
          loadingVoyage.finish();
        });
      }
    });

    const customRow = (record) => {
      return {
        onClick: () => {
          if (selectedVoyage.id === record.voyage_id) {
            return;
          }

          return router.push({
            name: "History",
            params: { id: record.voyage_id },
          });

          // loadingVoyage.start();

          // voyages.fetchVoyageDetails(record.voyage_id).then(() => {
          //   loadingVoyage.finish();
          // });
        },
      };
    };

    const columns = ref([
      {
        title: t('history-page.vessel'),
        key: "vessel",
        children: [
          {
            title: t('history-page.name'),
            dataIndex: "vessel_name",
            key: "vessel_name",
            width: 100,
            sorter: (a, b) => a.vessel_name.length - b.vessel_name.length,
          },
          {
            title: t('history-page.type'),
            dataIndex: "vessel_type",
            key: "vessel_type",
            width: 100,
            sorter: (a, b) => a.vessel_type.length - b.vessel_type.length,
          },
        ],
      },
      {
        title: t('history-page.voyage'),
        key: "voyage",
        children: [
          {
            title: t('history-page.nom'),
            dataIndex: "voyage_id",
            key: "voyage_id",
            width: 100,
            sorter: (a, b) => a.voyage_id - b.voyage_id,
          },
          {
            title: t('history-page.type'),
            dataIndex: "voyage_type",
            key: "voyage_type",
            width: 100,
            sorter: (a, b) => a.voyage_type.length - b.voyage_type.length,
          },
        ],
      },
      {
        title: t('history-page.departure'),
        key: "departure",
        children: [
          {
            title: t('history-page.port'),
            dataIndex: "departure_port_name",
            key: "departure_port_name",
            width: 100,
            sorter: (a, b) =>
              a.departure_port_name.length - b.departure_port_name.length,
          },
          {
            title: t('history-page.date time'),
            dataIndex: "departure_datetime",
            key: "departure_datetime",
            width: 100,
            sorter: (a, b) => a.departure_datetime - b.departure_datetime,
          },
        ],
      },
      {
        title: t('history-page.arrival'),
        key: "arrival",
        children: [
          {
            title: t('history-page.port'),
            dataIndex: "arrival_port_name",
            key: "arrival_port_name",
            width: 100,
            sorter: (a, b) =>
              a.arrival_port_name.length - b.arrival_port_name.length,
          },
          {
            title: t('history-page.date time'),
            dataIndex: "arrival_datetime",
            key: "arrival_datetime",
            width: 100,
            sorter: (a, b) =>
              a.arrival_datetime.length - b.arrival_datetime.length,
          },
        ],
      },
    ]);

    return {
      date,
      icons,
      columns,
      dataSource,
      customRow,
      voyages,
      list,
      selectedVoyage,
      voyageDetails,
      isLoading: loading.active,
      isLoadingVoyage: loadingVoyage.active,
    };
  },

  components: {
    HistoryDetails,
  },
});
</script>

<style lang="less" scoped>
.pointer {
  cursor: pointer;
}
.loading {
  width: 100vw;
  height: 100vh;
  padding: 10px 10px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.75rem 2.8rem 1.75rem 2.8rem;

  h3.ant-typography {
    text-transform: uppercase;
  }

  &-left {
    display: flex;
    align-items: center;
  }

  &-filter {
    display: flex;
    align-items: center;
    margin-left: 6.4rem;
    gap: 1rem;
  }

  &-right {
    display: flex;
    align-items: center;
    gap: 4.8rem;

    .search {
      width: 24rem;
      position: relative;

      .ant-input {
        border-radius: 0px;
      }

      &-button {
        font-size: 1.8rem;
        position: absolute;
        top: 0.2rem;
        right: 0.8rem;

        &:not(:hover) {
          color: @color-secondary;
        }
      }
    }

    .ant-dropdown-link {
      &:not(:hover) {
        color: @color-secondary;
      }
    }

    .export-report {
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

.table {
  height: calc(100vh - 14.4rem);
  display: flex;

  &-head {
    text-transform: uppercase;
    text-align: left;
  }

  &-check {
    color: @color-active;
    display: flex;
    justify-content: center;
    opacity: 0.5;
  }

  &-action {
    display: flex;
    justify-content: flex-end;

    a {
      &:not(:hover) {
        color: @color-secondary;
      }
    }
  }

  .icon-arrow {
    display: flex;
    align-items: center;

    img {
      height: 1.2rem;
    }
  }
}
</style>
